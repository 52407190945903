<template>
  <div class="nursery">
    <page-header title="Crèche" icon="fa fa-baby-carriage"></page-header>
    <div class="nursery-days">
      <b-row class="date-header">
        <b-col>
          <b>{{ dayDate|dateToString('dddd LL') }}</b>
        </b-col>
        <b-col cols="3" class="text-right nav-date">
          <a class="btn btn-sm btn-secondary" href @click.prevent="setLastWeek()" title="Semaine précédente">
            <i class="fa fa-angle-double-left"></i>
          </a>
          <a class="btn btn-sm btn-secondary" href @click.prevent="setYesterday()" title="Hier">
            <i class="fa fa-chevron-left"></i>
          </a>
          <a class="btn btn-sm btn-secondary" href @click.prevent="setToday()" title="Aujourd'hui">
            <i class="fa fa-calendar-o"></i>
          </a>
          <a class="btn btn-sm btn-secondary" href @click.prevent="setTomorrow()" title="Demain">
            <i class="fa fa-chevron-right"></i>
          </a>
          <a class="btn btn-sm btn-secondary" href @click.prevent="setNewtWeek()" title="Semaine suivante">
            <i class="fa fa-angle-double-right"></i>
          </a>
        </b-col>
        <b-col cols="3" class="text-right">
          <b-input type="date" v-model="rawDateAsString" id="seanceDay"></b-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <loading-gif :loading-name="loadingName"></loading-gif>
          <div v-if="nurseries.length === 0" class="empty-text">
            Aucune inscription
          </div>
          <div v-for="elt of nurseries" :key="elt.nursery.id" class="row-line">
            <b-row>
              <b-col cols="4">
                <b>{{ elt.nursery.getLabel() }}</b>
              </b-col>
              <b-col cols="3">
                <router-link :to="getLinkToActivity(elt)" v-if="canViewActivity">
                  Liste des adhérents
                </router-link>
              </b-col>
              <b-col>
                <b-row>
                  <b-col cols="7">
                    <router-link :to="getNurseryInscriptionsRoute(elt.nursery)">
                      {{ dayDate | dateToString('ddd D MMM YYYY') }}
                    </router-link>
                  </b-col>
                  <b-col cols="5" class="text-right">
                    <counter-label :counter="elt.inscriptions" label="inscription"></counter-label>
                    <div class="small-text" v-if="elt.absences">
                      <counter-label :counter="elt.absences" label="absence"></counter-label>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col>
        <div>
          <ul class="app-menu">
            <li v-if="hasPerm('nursery.view_nurserycontract')">
              <router-link :to="{ name: 'nursery-contracts', }">
                <i class="fa fa-baby-carriage"></i> Liste des contrats
              </router-link>
            </li>
            <li v-if="hasAllPerms(['payments.add_invoice', 'nursery.view_nurseryinscription'])">
              <router-link :to="{ name: 'nursery-invoicing', }">
                <i class="fa fa-receipt"></i> Facturation Crèche
              </router-link>
            </li>
            <li v-if="hasPerm('nursery.change_nursery')">
              <router-link :to="{ name: 'nursery-checking', }">
                <i class="fa fa-check-double"></i> Vérification Crèche
              </router-link>
            </li>
            <li v-if="hasPerm('nursery.view_nurseryoffday')">
              <router-link :to="{ name: 'nursery-off-days', }">
                <i class="fa fa-calendar-xmark"></i> Jours de fermeture
              </router-link>
            </li>
            <li v-if="hasPerm('nursery.add_nurseryinscription')">
              <router-link :to="{ name: 'nursery-attendance', }">
                <i class="fa fa-table-list"></i> Fréquentation Crèche
              </router-link>
            </li>
            <li v-if="hasPerm('nursery.change_nursery')">
              <router-link :to="{ name: 'nursery-report', }">
                <i class="fa fa-file-o"></i> Prestation de Service Unique
              </router-link>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import CounterLabel from '@/components/Controls/CounterLabel'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeActivity } from '@/types/activities'
import { makeNursery } from '@/types/nursery'
import { BackendApi } from '@/utils/http'

export default {
  name: 'nursery-home',
  mixins: [BackendMixin],
  components: {
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  props: {
    day: {
      type: String,
      defaultValue: '',
    },
  },
  data() {
    return {
      loadingName: 'nursery-home',
      dayDateAsString: '',
      rawDateAsString: '',
      originalDate: '',
      nurseries: [],
    }
  },
  watch: {
    rawDateAsString() {
      if (this.rawDateAsString) {
        this.dayDateAsString = this.rawDateAsString
      }
    },
    dayDate: function(newValue) {
      let isValid = false
      if (newValue) {
        isValid = moment(newValue, 'YYYY-MM-DD').isValid()
      }
      if (isValid) {
        let newDate = dateToString(moment(newValue, 'YYYY-MM-DD').toDate(), 'YYYY-MM-DD')
        this.patchUrl(newDate)
        this.loadInscriptions(newValue)
      } else {
        this.rawDateAsString = dateToString(moment().toDate(), 'YYYY-MM-DD')
      }
    },
  },
  computed: {
    dayDate() {
      return moment(this.dayDateAsString, 'YYYY-MM-DD').toDate()
    },
    canViewActivity() {
      return this.hasPerm('activities.view_coreactivityinscription')
    },
  },
  mounted() {
    this.rawDateAsString = this.day || dateToString(moment().toDate(), 'YYYY-MM-DD')
    this.dayDateAsString = this.rawDateAsString
    this.originalDate = this.day
    this.loadInscriptions(this.dayDateAsString)
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    setToday() {
      this.rawDateAsString = dateToString(moment().toDate(), 'YYYY-MM-DD')
    },
    setYesterday() {
      const date = moment(this.rawDateAsString).add(-1, 'days')
      this.rawDateAsString = dateToString(date.toDate(), 'YYYY-MM-DD')
    },
    setTomorrow() {
      const date = moment(this.rawDateAsString).add(1, 'days')
      this.rawDateAsString = dateToString(date.toDate(), 'YYYY-MM-DD')
    },
    setLastWeek() {
      const date = moment(this.rawDateAsString).add(-7, 'days')
      this.rawDateAsString = dateToString(date.toDate(), 'YYYY-MM-DD')
    },
    setNewtWeek() {
      const date = moment(this.rawDateAsString).add(7, 'days')
      this.rawDateAsString = dateToString(date.toDate(), 'YYYY-MM-DD')
    },
    getNurseryInscriptionsRoute(nursery) {
      return {
        name: 'nursery-inscriptions',
        params: {
          day: this.dayDateAsString,
          nurseryId: nursery.id,
        },
      }
    },
    async loadInscriptions(seanceDate) {
      this.startLoading(this.loadingName)
      let dateAsTr = dateToString(seanceDate, 'YYYY-MM-DD')
      let url = '/nursery/api/nurseries-daily/' + dateAsTr + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.nurseries = resp.data.map(
          elt => {
            return {
              nursery: makeNursery(elt.nursery),
              activity: makeActivity(elt.activity),
              inscriptions: elt.inscriptions,
              absences: elt.absences,
            }
          }
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    patchUrl(newValue) {
      // met à jour l'url (sans rechargement) avec la date sélectionné si
      // différente de la date du jour
      let initialPath = this.$route.path
      let today = dateToString(moment().toDate(), 'YYYY-MM-DD')
      let updatedPath = ''
      if (this.originalDate) {
        updatedPath = initialPath.replace(this.originalDate, newValue)
      } else {
        if (newValue === today) {
          updatedPath = initialPath
        } else {
          updatedPath = initialPath + '/' + newValue
        }
      }
      history.pushState(
        {},
        null,
        updatedPath
      )
    },
    getLinkToActivity(elt) {
      return { name: 'activities-detail', params: { activityId: '' + elt.activity.id, }, }
    },
  },
}
</script>

<style lang="less">
  .nursery-days {
    margin-top: 20px;
    padding: 10px;
  }
  .date-header {
    margin-bottom: 5px;
  }
  .daily-workshops {
    margin-top: 30px;
  }
  .nav-date a {
    margin: 5px 2px;
  }
</style>
